<template>
  <v-expansion-panels accordion multiple>
    <v-expansion-panel>
      <v-expansion-panel-header class="px-2">Альфалаб</v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="mx-n4 mt-1">
          <div v-if="typeof localOptionsJson!=='undefined' && localOptionsJson!==null && typeof localOptionsJson.alfalab!=='undefined'" class="mb-5">
            <v-row v-for="(v,k) in localOptionsJson.alfalab" :key="'optionsJson'+k" class="rowSmall">
              <v-col cols="4">
                <v-text-field v-model="v.code" label="Код услуги" dense outlined clearable hide-details />
              </v-col>
              <v-col>
                <v-textarea v-model="v.options" label="Биоматериалы (через запятую)" dense outlined hide-details auto-grow rows="1" />
              </v-col>
              <v-col cols="auto">
                <btn-icon-accept @accept="localOptionsJson.alfalab.splice(k, 1)" icon="mdi-close" text="Удалить?"/>
              </v-col>
              <v-col v-if="k<localOptionsJson.alfalab.length-1" cols="12">
                <v-divider/>
              </v-col>
            </v-row>
          </div>
          <v-btn @click="add('alfalab')" block depressed>Добавить</v-btn>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import BtnIconAccept from "@/componentsV2/base/BtnIconAccept.vue";

export default {
  components: {BtnIconAccept},
  props:['optionsJson'],
  data:()=>({
    localOptionsJson:null,
  }),
  mounted() {
    console.log(this.optionsJson);
    this.localOptionsJson = this.optionsJson;
  },
  watch: {
    select() {
      this.localOptionsJson = this.optionsJson;
    },
    localOptionsJson() {
      this.$emit('optionsJson', this.localOptionsJson)
    },
  },
  methods:{
    add(name){
      let d = null;
      if(this.localOptionsJson===null)
        d = {}
      else if(this.localOptionsJson.length===0)
        d = {}
      else
        d = this.$tools.copy(this.localOptionsJson);

      if(typeof d[name]==='undefined')
        d[name] = [];

      d[name].push(
          name==='alfalab' ? {code:'',options:''} :
          name==='kdl' ? {form: '', code:'',options:''} :
          null
      );

      this.localOptionsJson = d;
    }
  }
}
</script>