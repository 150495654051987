<template>
  <layout-view
      :navTitle="tabIndex===0 ? '' : 'Фильтры'"
  >
    <template v-slot:tabs>
      <v-tabs v-model="tabIndex">
        <v-tab>Планы лечения</v-tab>
        <v-tab>Услуги</v-tab>
      </v-tabs>
    </template>
    <template v-slot:nav v-if="tabIndex===1">
      <div class="px-4 pb-2">
        <v-radio-group value="all" class="mt-0">
          <v-radio @click="visitServiceFilters.type=null; load()" value="all" label="Все"/>
          <v-radio @click="visitServiceFilters.type=1; load()" value="paySuccess" label="Оплаченные (оказанные)"/>
          <v-radio @click="visitServiceFilters.type=2; load()" value="payWait" label="Не оплаченные или с отмененным платежом"/>
        </v-radio-group>
        <v-text-field label="ID" v-model="visitServiceFilters.id" @keyup="load" class="mb-2" outlined dense hide-details="auto"/>
      </div>
    </template>
    <template v-slot:content="{height}">
      <template v-if="tabIndex===0">
      <table-view         
          :url="'/api/medPlan/medPlan/?userId='+$route.params.id"
          title="Список планов лечения"
          :headers="[
            { text: 'ID', sortable: false},
            { text: 'Название', sortable: false},
            { text: 'Комментарий', sortable: false},
            { text: 'Начислено', sortable: false},
            { text: 'Отменено', sortable: false},
            { text: 'Оплачено', sortable: false},
            { text: 'Осталось', sortable: false},
            { text: 'Дата назначения', sortable: false},
            { text: 'Статус', sortable: false},
          ]"
          ref="medPlans"
          tableClass="tableStatused"
          :returnItems.sync="medPlansItems"
      >
        <template v-slot:actions>
          <med-plan-from-service-cat-create :userId="$router.currentRoute.params.id" @complete="medPlanCreated"/>
        </template>
        <template v-slot:item="{ item }">
          <tr @click="medPlanId=item.id;" :class="medPlanId===null ? '' : medPlanId===item.id ? 'active' : ''">
            <td class="text-wrap">{{ item.id }}</td>
            <td class="text-no-wrap">{{$tools.isEmpty(item.name) ? 'Без названия' : item.name}}</td>
            <td class="text-wrap">{{ item.comment }}</td>
            <td class="text-no-wrap">{{$tools.price(item.summary.total)}}</td>
            <td class="text-no-wrap">{{$tools.price(item.summary.cancel)}}</td>
            <td class="text-no-wrap">{{$tools.price(item.summary.success)}}</td>
            <td class="text-no-wrap">{{$tools.price(item.summary.wait)}}</td>
            <td class="text-no-wrap">{{item.begin===null ? '-' : $tools.date(item.begin)}}</td>
            <td class="text-no-wrap">
              <tooltip v-if="item.status === 'active'" right>              
                <v-icon class="mr-2" size="18" color="primary" style="margin-top:-1px">mdi-checkbox-blank-circle-outline</v-icon>
                <template v-slot:content>
                  Активен
                </template>
              </tooltip>

              <tooltip v-else-if="item.status === 'achieved'" right>              
                <v-icon class="mr-2" size="18" color="purple" style="margin-top:-1px">mdi-checkbox-marked-circle-auto-outline</v-icon>
                <template v-slot:content>
                  Цель достигнута
                </template>
              </tooltip>

              <tooltip v-else-if="item.status === 'done'" right>              
                <v-icon class="mr-2" size="18" color="warning" style="margin-top:-1px">mdi-check-all</v-icon>
                <template v-slot:content>
                  Выполнен
                </template>
              </tooltip>



              <tooltip v-else right>              
                <v-icon class="mr-2" size="18" color="success" style="margin-top:-1px">mdi-check-decagram</v-icon>
                <template v-slot:content>
                  Завершен
                </template>
              </tooltip>
            </td>
          </tr>
        </template>
        <template v-slot:add-item>
          <tr>
            <td colspan="6">
              <b>Итого:</b> 
            </td>
            <td>
              <b> {{ medPlansTotalPrice }} ₽</b>
            </td>
          </tr>
        </template>
      </table-view>
      </template>
      <table-view
          v-if="tabIndex===1"
          :url="visitServicesUrl"
          title="Список услуг"
          :headers="[
            { text: 'ID', sortable: false},
            { text: 'Название', sortable: false},
            { text: 'Организация', sortable: false},
            { text: 'План лечения: Рекомендуемая дата', sortable: false},
            { text: 'Визит', sortable: false},
            { text: 'Итого', sortable: false, class:'text-end'},
            { text: 'Оплата', sortable: false},
          ]"
          ref="visitServices"
          key="visitServices"
          :height="height"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-wrap">{{ item.id }}</td>
            <td class="text-wrap" style="width:100%">
              <div>{{ item.name }}</div>
              <div v-if="item.comment!==null" class="mt-1">
                <v-icon small>mdi-comment-text-outline</v-icon>
                <div class="caption mt-n5 ml-5">{{item.comment}}</div>
              </div>
            </td>
            <td class="text-no-wrap" v-if="item.insur">{{ item.insur.name }}</td>
            <td class="text-no-wrap" v-else>-</td>
            <td class="text-no-wrap" style="width:100%">
              {{
                item.medPlanAfterDays===null ?
                    '' :
                    $tools.date(item.medPlanAfterDate)
              }}
            </td>
            <td class="text-wrap" style="width:100%">
              <div v-if="item.visit===null">-</div>
              <div v-else>
                <v-btn @click="visitId=item.visit.id" depressed small>
                  {{item.visit.begin|date}}
                </v-btn>
              </div>
            </td>
            <td class="text-no-wrap text-right">
              {{$tools.price(item.priceTotal)}}
            </td>
            <td class="status">
              <visit-service-status :status="item.payStatus"/>
            </td>
          </tr>
        </template>
      </table-view>
      <med-plan :medPlanId.sync="medPlanId" @cancel="$refs.medPlans.reload()"/>
      <visit :visitId.sync="visitId" @cancel="$refs.visitServices.reload()"/>
    </template>
  </layout-view>
</template>

<script>
import TableView from "@/components/TableView";
import MedPlan from "@/views/MedPlan/MedPlan";
import State from "@/plugins/state";
import VisitServiceStatus from "@/components/VisitServiceStatus";
import Visit from "@/views/Visit/Visit";
import MedPlanFromServiceCatCreate from "@/views/MedPlan/MedPlanFromServiceCatCreate";
import LayoutView from "@/components/LayoutView";
import Tooltip from "@/componentsV2/base/Tooltip.vue";

export default {
  components: {
    LayoutView,
    MedPlanFromServiceCatCreate, Visit, VisitServiceStatus, MedPlan, TableView, Tooltip},
  data: () => ({
    tabIndex:0,

    medPlanId:null,

    visitId:null,
    visitServiceFilters:{
      id:null,
      type:null,
    },
    visitServicesUrl:null,

    medPlanCreateState: new State(),
    medPlansItems: [],
    medPlansTotalPrice:0
  }),
  watch: {
    medPlansItems() {
      this.medPlansTotalPrice = 0;
      this.medPlansItems.forEach(i=>{
        this.medPlansTotalPrice += i.summary.total;
      });
    }
  },
  mounted(){
    this.load();
  },
  methods:{
    load() {
      this.visitServicesUrl = '/api/visit/visitService/?userId='+this.$router.currentRoute.params.id+'&filters='+
          JSON.stringify(this.visitServiceFilters, (key, value) =>{
            if(value===-1)
              value = null;
            if (!this.$tools.isEmpty(value))
              return value;
          });
    },

    medPlanCreated(id){
      this.medPlanId = id;
    },
  }
}
</script>


